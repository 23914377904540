@media only screen and (min-width: 960px) {
  .navbar-light .navbar-nav .nav-link:not(:last-child)::after {
    color: #ffa4ad;
    content: " \2022";
    margin-left: 1em;
  }
}

.navbar-light .navbar-nav .nav-link {
  color: royalblue;
  font-weight: 600;
}

.navbar-container {
  background-color: #ff8000 !important;
}

.general-block-item {
  padding: 50px 5px 1px 5px;
}

.doc-link {
  color: darkred;
  border: 0 none; 
}

.list-group-item.active {
  color: darkred;
  background-color: rgb(245, 243, 243);
  border-color: rgb(245, 243, 243);
}

.swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction{
  padding-bottom: 3px;
}

a:link { text-decoration: none;}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  text-align: center;
  line-height: 15px;
  font-size: 10px;
  color: #000;
  opacity: 1;
  background-color: white;
}

.swiper-pagination-bullet-active {
  color: #fff;
  background: #007aff;
}

.mySwiper{
  padding-top: 15px;
}
